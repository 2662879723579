import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src430903205/src/dashbot-home-gatsby/src/layouts/blog/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`At Facebook’s annual developer conference F8 earlier this month, Facebook executives took the stage to make announcements running the gamut from virtual reality to a new machine learning experimentation tool. Most relevantly to us at Dashbot, they also made announcements surrounding the Messenger app. The Messenger platform is home to over `}<a parentName="p" {...{
        "href": "https://www.facebook.com/business/news/f8-2019-making-it-easier-for-businesses-to-connect-with-customers-on-messenger"
      }}>{`300,000 developers and sees over 20 billion messages`}</a>{` exchanged between businesses and consumers each month. It’s a powerful conversation channel and Facebook is making it easier for people and brands to start talking with these new features.`}</p>
    <p><img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2019/05/14135712/300000-1024x768.jpg",
        "alt": null
      }}></img></p>
    <h2>{`Expanding Messenger`}</h2>
    <p>{`Soon you’ll be able to access Messenger on more than just on a browser and through the app. Facebook announced that a desktop version of the Messenger app will be coming to all screens, making the platform more readily accessible. Customers will be able to more easily reach businesses and vice versa as well. Facebook also announced Project LightSpeed, an initiative aimed to improve the mobile Messenger app’s architecture, making it faster and lighter. Expect a smoother user experience, including chatbot interactions.`}</p>
    <p>{`Facebook wants to `}<a parentName="p" {...{
        "href": "https://www.mobilescout.com/tech/news/n112584/facebook-chat-across-whatsapp-instagram-messenger.html"
      }}>{`break conversation silos`}</a>{` among its platforms and offer a unified experience. They’re merging the backends of Messenger, WhatsApp, and Instagram and users will be able to communicate seamlessly across all three platforms. Messenger and WhatsApp are already home to a lot of chatbots, and it’ll be interesting to see how the chatbot ecosystem will evolve to fit this new space.`}</p>
    <h2>{`Frictionless Appointments`}</h2>
    <p>{`The Messenger Platform API will now have an appointment booking interface that’s capable of integrating with a company’s calendar system that’ll be shared it in conversation with the user and create a smoother customer journey. Customers will also experience a more frictionless booking experience since they’ll be able to see which appointments are open in real time. With this feature, businesses can now automate appointment bookings and even event reminders, which will increase bookings and decrease no-show rates. Such a straightforward use case could encourage more businesses to adopt a chatbot.`}</p>
    <p><img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2019/05/14140510/results-1024x676.jpg",
        "alt": null
      }}></img></p>
    <h2>{`Lead Generation`}</h2>
    <p>{`Facebook is adding a lead generation template into Ads Manager, which allows businesses to direct interested customers into a Messenger chatbot. Users will be able to start a conversation with a business while the business will get the opportunity to learn more about their customers. Moreover, this is a great way to automate the lead qualification process since this feature will create a list of truly interested users without any effort on your part. An added bonus is businesses’ awareness of the potential chatbots have in the lead generation automation use case will increase. With Facebook upping Messenger’s business capabilities, we can expect to see more chatbots in the future.`}</p>
    <h2>{`About Dashbot`}</h2>
    <p><em parentName="p"><a parentName="em" {...{
          "href": "https://www.dashbot.io/"
        }}>{`Dashbot`}</a>{` is a conversational analytics platform that enables enterprises and developers to understand user behaviors, optimize response effectiveness, and increase user satisfaction through actionable data and tools.`}</em></p>
    <p><em parentName="p">{`In addition to traditional analytics like engagement and retention, we provide chatbot specific metrics including NLP response effectiveness, sentiment analysis, conversational analytics, and the full chat session transcripts.`}</em></p>
    <p><em parentName="p">{`We also have tools to take action on the data, like our live person take over of chat sessions and broadcast messaging for re-engagement.`}</em></p>
    <p><em parentName="p">{`We support Alexa, Google Home, Facebook Messenger, Slack, Twitter, Kik, SMS, web chat, and any other conversational interface.`}</em></p>
    <p><a parentName="p" {...{
        "href": "https://www.dashbot.io/contact/sales/?ref=blog+f8"
      }}><br parentName="a"></br>
        {`Contact us for a demo `}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      